$(document).ready(function () {
	const item = $('.c-home-features__item');
	const graphic = $('.c-home-features__graphic');
	var currentIndex = 0;

	function toggleItems(index) {
		item.removeClass('--is-active');
		item.eq(index).addClass('--is-active');
		graphic.removeClass('--is-active');
		graphic.eq(index).addClass('--is-active');
	}
	function rotateItems(index) {
		toggleItems(index);
		animateFeatures(index);
		currentIndex = (index + 1) % item.length;
	}

	function animateFeatures(index) {
		index = index + 1;

		if (index == 0) {
			index = item.length;
		}

		document
			.getElementById(
				$('.c-home-features__graphic.--' + index)
					.find('svg')
					.attr('id')
			)
			.svgatorPlayer.restart();
	}

	item.on('click', function () {
		currentIndex = (item.index(this) + 1) % item.length;

		clearInterval(timer);

		timer = setInterval(function () {
			rotateItems(currentIndex);
		}, 8000);

		if (!item.eq(currentIndex - 1).hasClass('--is-active')) {
			toggleItems(currentIndex - 1);
			animateFeatures(currentIndex - 1);
		}
	});

	rotateItems(currentIndex);
	animateFeatures(currentIndex);

	var timer = setInterval(function () {
		rotateItems(currentIndex);
	}, 8000);
});
